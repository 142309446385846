.link-item {
    display: flex;
    margin: 10px auto; /* Centering each item and adding margin */
    padding: 15px;
    background-color: #333333; 
    color: white;
    border-radius: 10px; /* Rounded corners */
    max-width: 500px; /* Maximum width of each item */
    width: 100%; /* Full width */
  }

  .link-image-container {
    width: 100px; /* Same width as the image */
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .link-image-spacer {
    width: 100px;
    margin-right: 15px;
  }
  
  .link-image {
    align-self: left;
    width: 100px; 
    height: 100px; 
    margin-right: 15px; /* Space between image and text */
    object-fit: cover; /* Ensure image covers the area nicely */
    border-radius: 5px;
  }
  
  .link-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align text to the start */
    /*justify-content: space-between; /* Spread out the children */
    width: 100%; /* Take full width to align children properly */
  }

  .language-container {
    margin-top: auto; /* Pushes the language to the bottom */
    width: 100%;
  }

  .language-image {
    height: 10px
  }
  
  .link-title {
    font-size: 18px;
    text-align: left;
    font-style: bolder;
    margin-bottom: 10px; /* Pushes everything else down */
  }
  
  .link-description {
    font-size: 14px;
    text-align: left; /* Align text to the left */
    margin-top: auto; /* Stays at the bottom */
  }

  .link-language {
    font-size: 14px;
    text-align: left; /* Align text to the left */
  }

  @media (max-width: 600px) { /* Adjust the max-width as needed */
    .link-item {
      max-width: 400px;
    }  
  }

  @media (max-width: 500px) { /* Adjust the max-width as needed */
    .link-item {
      max-width: 350px;
    }  
  }

  @media (max-width: 400px) { /* Adjust the max-width as needed */
    .link-item {
      max-width: 300px;
    } 
  }

  @media (max-width: 350px) { /* Adjust the max-width as needed */
    .link-item {
      max-width: 250px;
    } 
  }

  @media (max-width: 280px) { /* Adjust the max-width as needed */
    .link-item {
      max-width: 200px;
    } 
  }


  
  