
body, html {
  background-color: black;
  height: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  color: white;
}

#root, .App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a:hover,
a:visited,
a:active {
  color: white; /* Keep text color white on hover, visited, and active */
  text-decoration: none; /* Remove underline */
}

a {
  text-decoration: none; /* Remove underline */
  -webkit-tap-highlight-color: transparent; /* Makes tap highlight color transparent */
}
