.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.App-footer {
  text-align: center;
  padding: 20px;
  background-color: black; /* Set background color to black */
  left: 0;
  bottom: 0;
  width: 100%;
}

.social-links a, .App-footer span a {
  display: inline-block;
}

span {
  padding-right: 50px;
}

.App-footer a:hover,
.App-footer a:visited,
.App-footer a:active {
  color: white; /* Keep text color white on hover, visited, and active */
}

.App-footer a {
  margin-right: 30px; /* Spacing between links */
  color: white; /* Text color */
  text-decoration: none; /* Remove underline */
  font-size: 14px; /* Smaller font size */
}

.App-footer .social-links {
  text-align: center;
  margin-top: 10px; /* Space above the social links */
}

.App-footer .social-links img {
  width: 30px; /* Adjust as necessary */
  height: auto;
}
