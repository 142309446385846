.menu {
    background-color: black; 
    color: white;
  }
  
  .menu-header {
    position: relative; /* Relative positioning for the container */
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 30px;
    padding: 10px;
    height: 60px;
  }

  
  .current-tab {
    position: absolute; /* Absolute positioning for the title */
    left: 50%; /* Center the title */
    transform: translateX(-50%); 
    /* Ensure the title doesn't overlap the logo */
    padding-left: 17px; 
  }
  
  .menu-tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap; /* Ensures tabs are in a single line */
    justify-content: center; /* Center the tab items */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
    position: relative;
    -webkit-tap-highlight-color: transparent; /* Makes tap highlight color transparent */

    /* For Chrome, Safari, and Opera */
    ::-webkit-scrollbar {
        display: none;
    }
  }

  /* For Chrome, Safari, and Opera: Use this if the above doesn't work */
  .menu-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .gradient { 
    content: '';
    position: relative;
    top: 0;
    bottom: 0;
    z-index: 1; /* Ensure it's above other elements */
    pointer-events: none;
    width: 80px; 
    margin-top: -50px;
    height: 50px;
  }

  #left-gradient {
    background: linear-gradient(to left, transparent, #000000 90%);
  }
  
  #right-gradient {
    float: right;
    background: linear-gradient(to right, transparent, #000000 90%);
  }
  
  .tab-item {
    flex: 0 0 auto; /* Don't grow or shrink */
    margin-top: 5px;
    margin-right: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 10px;
    background: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    border: 2px solid white;
    border-radius: 100px; /* Rounded corners */
  }
  
  .tab-item.active {
    color: black;
    background-color: white;
  }
    
  .menu-logo {
    position: absolute; /* Absolute positioning for the logo */
    left: 10px; 
    margin-top: 10px;
    width: 210px; 
    height: auto;
  }
  
  @media (max-width: 800px) {
    .menu-tabs {
      justify-content: left; /* Align tabs to left if not all menu items visible at once */
    }
  }

  
  @media (max-width: 768px) {

    .menu-logo {
      width: 65px; /* Smaller size for smaller screens */
    }
  }


  .scroll-button {
    position: absolute;
    top: 100px;
    transform: translateY(-50%);
    background-color: #333333;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2; /* Ensure it's above other elements */
    width: 35px; 
    height: 35px; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scroll-button.left {
    left: 0; /* Align to the left edge */
  }
  
  .scroll-button.right {
    right: 0; /* Align to the right edge */
  }


  .language-toggle {
    position: absolute;
    right: 0
  }